import React from "react";

import {
  Button,
  Card,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import api from './../api-service';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

class Skraning extends React.Component {
  constructor(props) {
    super(props);        

    this.state = {
      rass: 0,
      name: '',
      phone: '',
      code: '',
      recode: '',
      error: null,
      lok: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);      
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCode = this.handleCode.bind(this);
    this.nyrCode = this.nyrCode.bind(this)
    this.phoneValidation = this.phoneValidation.bind(this);
    this.finnaRass = this.finnaRass.bind(this);
    this.onKeyUpCode = this.onKeyUpCode.bind(this);
    this.onKeyUpSignup = this.onKeyUpSignup.bind(this);
  }

  onKeyUpCode(event) {
    if (event.charCode === 13) {
      event.preventDefault();
      this.handleCode(event);
    }
  }

  onKeyUpSignup(event) {
    if (event.charCode === 13) {
      this.handleSubmit(event)
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
        [name]: value
    });
  };

  phoneValidation() {
    const regex = /^([678]{1}\d{6})$/i;
    return !(!this.state.phone || regex.test(this.state.phone) === false);
  };

  finnaRass() {
    const phn = this.state.phone;
    console.log(phn)
    if (phn != null && phn.length > 6) {
        fetch(api.root+'/rass?phn='+phn)
        .then(response => response.json())
        .then(data => {
            for (let i = 0; i < data.length; i++) {
                this.setState({
                  error: 'Þetta númer er þegar á skrá',
                  code: '',
                });
                console.log(data[i])   
              }
        });
    }
  };
  
  handleSubmit(event) {
    // Tékka á hvort númer finnst
    this.finnaRass();
    //console.log('sími: '+this.state.phone+', nafn: '+this.state.name);
    if (!this.phoneValidation()) {
      this.setState({code: '', error: 'Þetta er ekki gilt farsímanúmer'});
    } else {
      if (this.state.rass !== 0) {
        this.setState({rass: 0, code: '', error: 'Þetta númer er þegar á skrá'});
      } else {
        // Ef svo er ekki, bý til kóða
        const code = getRandomInt(100,999).toString();
        //console.log(code);
        // Sendi kóðann í SMS
        let formData = new FormData();
        formData.append('code', code);
        formData.append('phone', this.state.phone);
        
        fetch(api.root+'/code/0/new/', {
            body: formData,
            method: "post"
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
              this.setState({ error: data.error });
            } else {
              this.setState({ error: data.message});
            }
        });
  
        this.setState({ code: code, lok: false, error: null });    
      }
    }
    if (this.state.phone && this.state.name && true === false) {
      let formData = new FormData();
      formData.append('name', this.state.name);
      formData.append('phone', this.state.phone);
      
      fetch(api.root+'/rass/0/skraning/', {
          body: formData,
          method: "post"
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
            this.setState({ error: data.error });
          } else {
            this.setState({ rass: data.id, phone: "", name: 0, code: data.code, lok: false, error: null});
          }
      });
  }
  };
  
  handleCode() {
  
    console.log('code: '+this.state.code+', recode: '+this.state.recode);
    
    if (this.state.code === this.state.recode && this.state.recode !== '') {
      let formData = new FormData();
      formData.append('name', this.state.name);
      formData.append('phone', this.state.phone);
      
      fetch(api.root+'/rass/0/skraning/', {
          body: formData,
          method: "post"
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
            this.setState({ error: data.error });
          } else {
            this.setState({ rass: data.id, code: "", lok: true, error: null});
          }
      });
      /*
      let formData = new FormData();
      formData.append('l', '1');
      
      fetch(api.root+'/rass/'+this.state.rass+'/laga/', {
          body: formData,
          method: "put"
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
            this.setState({ error: data.error });
          } else {
            this.setState({ rass: data.id, phone: '', name: '', vantar: '', code: '', lok: true, error: null});
          }
        });
        */
      } else {
        this.setState({ error: 'Rangur kóði' });
      }
    };  

nyrCode() {
  const code = getRandomInt(100,999).toString();
  // Sendi kóðann í SMS
  let formData = new FormData();
  formData.append('code', code);
  formData.append('phone', this.state.phone);
  fetch(api.root+'/code/0/new/', {
      body: formData,
      method: "post"
  })
  .then(response => response.json())
  .then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.setState({ error: data.message});
      }
  });
  this.setState({ code: code, lok: false, error: 'Nýr kóði sendur'});
}


  render() {
  return (
    <>
      <div id="innskraning" 
        className="section section-image-lighter section-login"
        style={{
          backgroundImage:
            "url(" + require("img/strond.jpg").default + ")",
        }}
      >
        <Container>
          <Row>
            <Col className="mx-auto" lg="4" md="6">
              <Card className="card-register">
                <h3 className="title mx-auto">Gakktu í gusuklúbbinn!</h3>
                {this.state.lok ?
                <>
                <h6>Skráning heppnaðist!</h6>
                  <p>{this.state.name} getur nú skráð sig í gusur með símanúmerinu {this.state.phone}.</p>
                </>
              : 
              <>
              <div><p>Áður en þú skráir þig í gusu í fyrsta sinn þarftu að nýskrá þig með því að gefa upp nafn og farsímanúmer.</p>
                <p>Þá muntu fá sendan kóða í SMS sem þú þarft að gefa upp.</p>
                </div>

                {this.state.code
                ?
                <Form className="register-form center">
                <label>Kóði</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroup.Text>
                        <i className="nc-icon nc-key-25" />
                        <Form.Control placeholder="Kóði" name="recode" type="number" inputmode="decimal" autoFocus onChange={this.handleInputChange} value={this.state.recode}  onKeyPress={this.onKeyUpCode} />
                    </InputGroup.Text>
                  </InputGroup>
                  {this.state.error && <p>{this.state.error}<br /> 
                  <Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={this.nyrCode}>Senda nýjan kóða</Button></p>}
                  <Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={() => { this.handleCode() }}
                  >
                    Senda
                  </Button>
                </Form>

                :
                <Form className="register-form center">
                <label>Nafn</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroup.Text>
                        <i className="nc-icon nc-single-02" />
                        <Form.Control placeholder="Nafn" name="name" type="text" onChange={this.handleInputChange} value={this.state.name}/>
                    </InputGroup.Text>
                  </InputGroup>
                  <label>Farsími</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroup.Text>
                        <i className="nc-icon nc-mobile" />
                        <Form.Control placeholder="Farsímanúmer" type="number" inputmode="decimal" name="phone" onChange={this.handleInputChange} value={this.state.phone} onKeyPress={this.onKeyUpSignup} />  
                    </InputGroup.Text>
                    {this.state.error &&
                      <p>{this.state.error}</p>  
                    }

                  </InputGroup>
                  <Button
                    
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={() => { this.handleSubmit() }}
                  >
                    Skrá
                  </Button>
                </Form>}
              </>}
              </Card>

            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
      }
}

export default Skraning;
