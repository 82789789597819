import React from "react";
import {
    Card,
    Container,
    Row,
    Col,
  } from "react-bootstrap";
  
function Um() {
  return (
    <>
        <div id="um" 
        className="section section-image-lighter"
        style={{
          backgroundImage:
            "url(" + require("img/sviti.jpg").default + ")",
        }}
        >

        <Container>
            <Row className="um um-a">
              <Col className="um-a">
              <h3 className="title mx-auto um-b">Hvað er gufugusa?</h3>
              <div className="mb-5">
                <p>Í gufugusu leiðir Gúsfrúin þrjár stuttar lotur inn í gufunni með viðeigandi ilmolíum, þara, tónlist, handklæðatilþrifum og blævængjum. </p>
                <p>Góður hiti í sánunni og kæling í hafinu, grasinu eða loftinu á milli. </p>
                <p>Boðið er upp á vatn og hressingu.</p>
                <p>Gusan tekur klukkutíma.</p>
              </div>
              </Col>
              <Col className="um-a">
              <h3 className="title mx-auto um-b">Hvað þarf að gera?</h3>
              <div className="mb-5">
                <p>Mikilvægt að koma með tvö handklæði, eitt til að sitja á inni í gufunni (svokallað svitaklæði) og annað til að þurrka sér eftir á. </p>
                <p>Mikilvægt að drekka vel og gott að taka með sér vatnsbrúsa fulla af fersku vatni. </p>
                <p>Svo er ljúft að hafa slopp til að smeygja sér í á milli og á eftir. </p>
                <p>Gott er að koma í sundfötum innan undir fötunum, annars er smá skiptiaðstaða í vagninum. </p>
              </div>
            </Col>
          </Row>
          <Row className="um um-b">
              <h3 className="title mx-auto um-a">EINKAGUSA<br />– einstök upplifun</h3>
              <Col className="um-b">
              <div className="mb-5 align-left">
              <p>Bókaðu Rjúkandi fargufu fyrir þinn eigin viðburð. Frábær og einstök upplifun!</p>
              <p>Rjúkandi býður upp á klukkutíma gufugusu með þrem lotum inni í gufunni þar sem gusuður dekrara við hópinn með dásamlegum ilmolíum, viðeigandi tónlist og blævængjum. Á milli lota er boðið upp á snakk og vatn ásamt kæling í sjónum, snjónum nú eða bara í íslenska svalanum. </p>
              </div>
              </Col>
              <Col className="um-b">
              <div className="mb-5">
              <p>Það komast allt að 12 manns í sánavagninn í einu, allt eftir því hversu þétt hópurinn er til í að sitja ;) </p>
              <p>Hægt er að útfæra viðburðinn fyrir stærri hópa, alltaf hægt að finna leiðir í gleðinni.</p> 
              <p>Verð er frá 50.000 krónum, fer eftir staðsetningu. Pantanir fara fram með skilaboðum á <a href="https://facebook.com/rjukandifargufa" target="_blank">fb síðu Rjúkanda</a>.</p>
              </div>
            </Col>
            </Row>
          </Container>
        </div>

    </>

  );
}

export default Um;

