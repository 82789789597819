import React from "react";
import Container from 'react-bootstrap/Container'

function Myndir() {
  return (
        <><div className="filter" />
        <div className="content-center">
          <Container>

          </Container>
      </div></>

  );
}

export default Myndir;

