import Header from './components/Header';
import Helst from './components/Helst';
import Um from './components/Um';
import Gusur from './components/Gusur';
import Skraning from './components/Skraning';
import Myndir from './components/Myndir';
import Footer from './components/Footer';

function App () {
    return (
    <>
      <Header />
      <Helst />
      <Skraning />
      <Gusur/>
      <Um />
      <Myndir />
      <Footer />
    </>
    );
}

export default App;
