import React from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  FloatingLabel,
} from "react-bootstrap";
import Moment from 'react-moment';
import api from './../api-service';


class Gusa extends React.Component {
    constructor(props) {
        super(props);        

        const { gusa } = this.props.gusa; 

        this.state = {
            gusa: gusa,
            rassar: 0,
            phone: "",
            rass: 0,
            nafn: "Hvert er símanúmerið þitt?",
            status: 0,
            stage: 0,
            vantar: 'Veldu fjölda',
        };

        this.handleSubmit = this.handleSubmit.bind(this);      
        this.handleInputChange = this.handleInputChange.bind(this);
        this.finnaRass = this.finnaRass.bind(this);
    }

     handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit(event) {
        const g = this.state.gusa.id;
        if (this.state.rass && this.state.rassar) {
            let formData = new FormData();
            formData.append('rassar', this.state.rassar);
            formData.append('phone', this.state.phone);
            
            fetch(api.root+'/gusa/'+g+'/panta/', {
                body: formData,
                method: "post"
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({ gusa: data.gusa, status: data.status, phone: "", rass: 0, rassar: "", nafn: "", vantar: ""});
                this.props.handler(data.gusa);
            });
        } else {
            this.setState({vantar: 'Veldu fjölda'})
        }
    };

    finnaRass() {
        const phn = this.state.phone;
        console.log(phn)
        if (phn != null && phn.length > 6) {
            this.setState({nafn: 'Leita...'});
            fetch(api.root+'/rass?phn='+phn)
            .then(response => response.json())
            .then(data => {
                for (let i = 0; i < data.length; i++) {
                    this.setState({
                      rass: data[i].id,
                      nafn: data[i].name,  
                      phone: data[i].phone,                   
                    });
                    console.log(data[i])   
                  }
            });
            if (this.state.rass === 0) {
                this.setState({nafn: 'Ekkert finnst! Ertu meðlimur í gusuklúbbnum?'});
            }
        } else {
            if (this.state.rass === 0) {
                this.setState({nafn: 'Sláðu inn símanúmer'});
            }
        }
    };
            
    render () {
        return [
            (
                <h5>{this.state.gusa.location}, <Moment tz="GMT" format="dddd D. MMMM">{this.state.gusa.start_date}</Moment> kl. <Moment tz="GMT" format="H.mm">{this.state.gusa.start_date}</Moment></h5>
            ),
            [(
                <Form.Group>
                    <Row className="g-2">
                    <Col xs={12} lg={6}>
                        <FloatingLabel controlId="floatingSelectGrid" label={this.state.nafn}>
                            <Form.Control type="number" pattern="[0-9]*" name="phone" onChange={this.handleInputChange} value={this.state.phone} placeholder="Hvert er símanúmerið þitt?"  disabled={this.state.rass !== 0}/>
                        </FloatingLabel>
                        
                        <Button className="float-end mt-1" variant="primary" onClick={() => { this.finnaRass() }}>
                            Áfram
                        </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                        <FloatingLabel controlId="floatingSelectGrid" label={this.state.vantar}>
                        <Form.Select 
                            name="rassar" 
                            aria-label="Hve mörg pláss?" 
                            className="form-control form-select" 
                            onChange={this.handleInputChange} 
                            value={this.state.rassar} 
                            disabled={this.state.rass === 0}>
                            <option>Hve mörg pláss?</option>
                            {Array.from(Array(this.state.gusa.laust).keys()).map(option => (<option key={option+1}>{option+1}</option>))}
                        </Form.Select>
                        </FloatingLabel>
                        
                        <Button id="skraningarhnappur" className="float-end mt-1" variant="primary" type="submit" onClick={() => { this.handleSubmit() }} disabled={this.state.rass === 0}>
                            Skrá!
                        </Button>

                    </Col>
                    
                    </Row>
                    </Form.Group>
                )
                ]
            ]
    };
}

export default Gusa;
