import React from "react";
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

function Footer() {
  return (
    <footer className="footer footer-black">
      <Container>
        <Row>
          <div className="credits ml-auto float-end">
            <span className="copyright float-end">
              © {new Date().getFullYear()} {" "}
                Fargufa slf. <i className="fa fa-heart heart" />
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
