import React from 'react';
import {
  Accordion,
  Container,
  Row,
  Col
} from "react-bootstrap";
import Gusulisti from './Gusulisti';
import api from './../api-service';

class Gusur extends React.Component {
  constructor(props) {
    super(props);        

    this.state = {
      gusur: null,
    };
  }
  
  componentDidMount() {
    const url = api.root+'/gusa';

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        this.setState({ gusur: data });
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  };

  render() {
  return (this.state.gusur === null) ? (
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Sæki gusur...</span>
      </div>
    </div>
    ) : (
      <div id="gusubak" className="section section-image-lighter"
        style={{
        backgroundImage:
          "url(" + require("img/eldur.jpg").default + ")",
        }}
      >
    <main>
<Container id="gusur" className="section-gusur section-gray">
  <Row>
    <Col xs={12} lg={12}>
    <h2 className="text-center">Gusur framundan</h2>
    <h5 className="text-center">Ekki gleyma að skrá þig í gusuklúbbinn svo þú getir skráð þig í gusur!</h5>
    <br />
    <Accordion>
      {this.state.gusur.map((gusa) => <Gusulisti key={gusa.id} index={gusa.id} gusa={{gusa}} />)}
    </Accordion>  
    </Col>
  </Row>
</Container>
      </main>
      </div>
  );
  }
}

 export default Gusur;
