import React from "react";

// reactstrap components
import { Container } from "react-bootstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("img/r2.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Rjúkandi fargufa</h1>
              <h2 className="presentation-subtitle text-center">
               <br />
              </h2>
             <div className="fog-low moving-clouds">
                <img
                  alt="..."
                  src={require("img/fog-low.png").default}
                />
              </div>
              <div className="fog-low right moving-clouds">
                <img
                  alt="..."
                  src={require("img/fog-low.png").default}
                />
              </div>
            </div>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("img/clouds.png").default + ")",
          }}
        />
        <h6 className="category category-absolute">
          
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
