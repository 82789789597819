import React from "react";
import classnames from "classnames";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function Header() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <Navbar.Brand
            data-placement="bottom"
            href="/"
            target="_blank"
          >
            Rjúkandi fargufa
          </Navbar.Brand>
                    
        </div>
        <Navbar.Collapse
          className="justify-content-end"
        >
          <Nav navbar>
            <Nav.Item>
              <Nav.Link
                data-placement="bottom"
                href="https://www.facebook.com/rjukandifargufa?ref=vefur"
                target="_blank"
                title="Fylgdu okkur á Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                data-placement="bottom"
                href="https://www.instagram.com/rjukandifargufa?ref=vefur"
                target="_blank"
                title="Fylgdu okkur á Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Button
                    className="btn-round" 
                    color="danger"
                    onClick={() => {
                    const anchor = document.querySelector('#gusur')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}>
                    <i className="nc-icon nc-sun-fog-29"></i> Gusur framundan
                </Button>

            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
