import React from 'react';
import {
  Accordion,
  Container, Row, Col,
  Card, Alert
} from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Moment from 'react-moment';
import Gusa from './Gusa';


function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button type="button" className="btn btn-primary" onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

class Gusulisti extends React.Component {
    constructor(props) {
      super(props);        
      const { gusa } = props.gusa; 
      this.state = {
        gusa: gusa,
        skraning: 0,
      };
    }

    handleChange = (someValue) => {
      this.setState({gusa: someValue, skraning: someValue.id});
      //setInterval(() => {
          //this.setState({skraning: 0});
      //}, 10000);
    }

    render () {
      const gusa = this.state.gusa;
      return [(
        <Card className="text-left card-plain" key='gusan+{gusa.id}'>
          <Card.Header>
            <Card.Body>
            <Container>
              <Row >
                <Col xs={12} lg={8}>
                  <strong>
                  <a target="_blank" href={gusa.latlon} rel="noreferrer">{gusa.location}</a>, <Moment tz="GMT" format="dddd D. MMMM YYYY">{gusa.start_date}</Moment> kl. <Moment tz="GMT" format="H.mm">{gusa.start_date}</Moment></strong>
                  <p><strong>{gusa.title}</strong> @{gusa.gusari}</p>
                  <p>{gusa.description}</p>
                </Col>
        {gusa.laust > 0
          ? 
                <><Col xs={4} lg={2} className='text-center align-middle'>
                    <p><strong>{gusa.laust}&nbsp;pláss</strong></p>
                    </Col>
                    <Col xs={4} lg={2} className='text-center align-middle'>
                      <CustomToggle eventKey={gusa.id} variant="primary">Velja!</CustomToggle>  
                    </Col></>
          :
                <><Col xs={8} lg={4}  className='text-center align-middle'>
                      <p><strong>Fullt!</strong></p>
                    </Col></>
          }
                </Row>
            </Container>
            </Card.Body>
          </Card.Header>
          {this.state.skraning > 0 && <Alert variant="success"><p>Skráning heppnaðist!</p></Alert>}  
          {gusa.laust > 0 &&
          <Accordion.Collapse eventKey={gusa.id}>
          <Card.Body key='opnagusan+{gusa.id}'>
            <div className="inner uk-grid gusa">
              <div className="uk-width-3-4">
                <Gusa handler={this.handleChange} key='opingusa-{gusa.id}' index={gusa.id} gusa={{gusa}}/>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>}
        </Card>
      )]
    }
}

export default Gusulisti;